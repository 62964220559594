<template>
  <b-row>
    <b-col lg="4">
      <b-card
        :img-src="require('@/assets/images/races/brief.jpg')"
        img-top
        class="card-profile"
      >
        <!-- 联赛信息 -->
        <div class="profile-image-wrapper">
          <div class="profile-image p-0">
            <b-avatar
              size="114"
              variant="light"
              :src="require(`../../assets/images/games/${gatPlatformIcon(Platform)}.jpg`)"
            />
          </div>
        </div>
        <h3>{{ RaceName }}</h3>
        <h6 class="text-muted">
          第{{ RaceStage }}站
        </h6>
        <b-badge
          class="profile-badge"
          variant="light-primary"
        >
          B级赛事
        </b-badge>
        <hr class="mb-2">

        <!-- 联赛数据 -->
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h6 class="text-muted font-weight-bolder">
              参赛车手
            </h6>
            <h3 class="mb-0">
              {{ Attendees }}
            </h3>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              最多完成圈
            </h6>
            <h3 class="mb-0">
              {{ MostLaps }}
            </h3>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              比赛用时
            </h6>
            <h3 class="mb-0">
              {{ RaceTime }}
            </h3>
          </div>
        </div>
      </b-card>
      <b-card>
        <b-row>
          <b-col>
            <b-badge variant="primary">
              {{ ServerSetup.FuelMult }}
            </b-badge>
            倍燃油消耗
          </b-col>
          <b-col>
            <b-badge variant="success">
              {{ ServerSetup.MechFailRate }}
            </b-badge>
            倍机械损耗
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <b-badge variant="info">
              {{ ServerSetup.TireMult }}
            </b-badge>
            倍轮胎损耗
          </b-col>
          <b-col>
            <b-badge variant="warning">
              {{ ServerSetup.DamageMult }}
            </b-badge>
            %车辆损耗
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col lg="8">
      <!-- 比赛结果概览 -->
      <b-card>
        <b-card-title>比赛信息</b-card-title>
        <b-table
          stacked
          response
          :items="infoItems"
          :fields="infoFields"
        />
        <b-card-title>比赛概述</b-card-title>
        <b-table
          response
          :items="resultsItems"
          :fields="resultFields"
        >
          <template #cell(FirstLapDriver)="data">
            <h-link
              v-if="data.item.FirstLapDid > 0"
              :id="data.item.FirstLapDid"
              :text="data.item.FirstLapDriver"
              to="driver"
              type="driver"
            />
          </template>
          <template #cell(SecondLapDriver)="data">
            <h-link
              v-if="data.item.SecondLapDid > 0"
              :id="data.item.SecondLapDid"
              :text="data.item.SecondLapDriver"
              to="driver"
              type="driver"
            />
          </template>
          <template #cell(ThirdLapDriver)="data">
            <h-link
              v-if="data.item.ThirdLapDid > 0"
              :id="data.item.ThirdLapDid"
              :text="data.item.ThirdLapDriver"
              to="driver"
              type="driver"
            />
          </template>

          <template #cell()="data">
            {{ data.value }}
          </template>
        </b-table>
        <b-card-title class="mt-1">
          圈速记录
        </b-card-title>
        <b-table
          response
          :items="lapRecordItems"
          :fields="lapRecordFields"
        >
          <template #cell(QualifyingPoleDriver)="data">
            <h-link
              v-if="data.item.QualifyingPoleDid > 0"
              :id="data.item.QualifyingPoleDid"
              :text="data.item.QualifyingPoleDriver"
              to="driver"
              type="driver"
            />
          </template>

          <template #cell(BestLapDriver)="data">
            <h-link
              v-if="data.item.BestLapDid > 0"
              :id="data.item.BestLapDid"
              :text="data.item.BestLapDriver"
              to="driver"
              type="driver"
            />
          </template>

          <template #cell()="data">
            {{ data.value }}
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Vue from 'vue'
import {
  BCard, BAvatar, BBadge, BCol, BRow, BTable, BCardTitle,
} from 'bootstrap-vue'
import axios from '@axios'
import HLink from '@/views/common/h-link.vue'

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
    BCol,
    BRow,
    BTable,
    BCardTitle,
    HLink,
  },
  props: {
    rid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      RaceId: 0,
      Attendees: 0,
      RaceTime: 0,
      MostLaps: 0,
      RaceName: '',
      RaceStage: '',
      Platform: '',
      HasTeam: 0,
      Round: [],
      Class: [],
      resultsItems: [],
      ServerSetup: {
        FuelMult: 0,
        TireMult: 0,
        DamageMult: 0,
        MechFailRate: 0,
      },
      resultFields: [
        { key: 'Round', label: '轮次' },
        { key: 'Class', label: '组别' },
        { key: 'FirstLapDriver', label: '冠军' },
        { key: 'SecondLapDriver', label: '亚军' },
        { key: 'ThirdLapDriver', label: '季军' },
      ],
      infoItems: [],
      infoFields: [
        { key: 'Date', label: '日期' },
        { key: 'Platform', label: '平台' },
        { key: 'RaceCategory', label: '赛事系列' },
      ],
      lapRecordItems: [],
      lapRecordFields: [
        { key: 'Round', label: '轮次' },
        { key: 'Class', label: '组别' },
        { key: 'QualifyingPoleDriver', label: '杆位' },
        { key: 'QualifyingPoleTime', label: '杆位圈速' },
        { key: 'BestLapDriver', label: '正赛最速' },
        { key: 'BestLaptime', label: '正赛最快圈' },
      ],
    }
  },
  created() {
    this.RaceId = Number(this.rid)
    axios.get(`${Vue.prototype.$Api_Race_Stat}${this.RaceId}`)
      .then(response => {
        this.Attendees = response.data[0].Attendees
        this.MostLaps = response.data[0].MostLaps
        this.RaceTime = response.data[0].RaceTime
      })
    axios.get(`${Vue.prototype.$Api_Race_Info}${this.RaceId}`)
      .then(response => {
        this.RaceStage = response.data[0].RaceStage
        this.ServerSetup.FuelMult = response.data[0].FuelMult
        this.ServerSetup.TireMult = response.data[0].TireMult
        this.ServerSetup.DamageMult = response.data[0].DamageMult
        this.ServerSetup.MechFailRate = response.data[0].MechFailRate
        this.infoItems.push({
          Date: response.data[0].Date,
          Platform: response.data[0].Platform,
          RaceCategory: response.data[0].RaceCategory,
        })
        response.data.forEach(element => {
          // 获得唯一的Round和Class
          this.Round.push(element.Round)
          this.Round = Array.from(new Set(this.Round))
          this.Class.push(element.Class)
          this.Class = Array.from(new Set(this.Class))
        })
        this.generateRaceResults(response.data)
      })
    axios.get(`${Vue.prototype.$Api_Race_Series}${this.RaceId}`)
      .then(response => {
        this.RaceName = response.data[0].SeriesName
        this.Platform = response.data[0].Platform
        this.HasTeam = response.data[0].HasTeam
      })
  },
  methods: {
    gatPlatformIcon(value) {
      return this.$gatPlatformFileName(value)
    },
    generateRaceResults(data) {
      data.forEach(x => {
        let round = ''
        if (x.Round === 0) {
          round = '单轮制'
        } else if (x.Round === 1) {
          round = '第一轮'
        } else if (x.Round === 2) {
          round = '第二轮'
        }
        this.resultsItems.push({
          Round: round,
          Class: x.Class,
          FirstLapDriver: x.FirstLapDriver,
          FirstLapDid: x.FirstLapDid,
          SecondLapDriver: x.SecondLapDriver,
          SecondLapDid: x.SecondLapDid,
          ThirdLapDriver: x.ThirdLapDriver,
          ThirdLapDid: x.ThirdLapDid,
        })
        this.lapRecordItems.push({
          Round: round,
          Class: x.Class,
          QualifyingPoleDriver: x.QualifyingPoleDriver,
          QualifyingPoleDid: x.QualifyingPoleDid,
          QualifyingPoleTime: this.$convertToLapTime(x.QualifyingPoleTime),
          BestLapDriver: x.BestLapDriver,
          BestLapDid: x.BestLapDid,
          BestLaptime: this.$convertToLapTime(x.BestLaptime),
        })
      })
    },
  },
}
</script>
