<template>
  <b-card-code
    title="比赛结果"
    no-body
  >
    <!-- 两轮制的成绩显示 -->
    <div v-if="IsDouble">
      <span class="ml-2 mb-1">
        第一轮
      </span>
      <b-table
        :fields="fields"
        :items="itemsR1"
        striped
        responsive
        class="mb-0 mt-1"
      >
        <template #cell(Driver)="data">
          <h-link
            v-if="data.item.Did > 0"
            :id="data.item.Did"
            :text="data.item.Driver"
            to="driver"
            type="driver"
          />
        </template>
        <!-- 安全分 -->
        <template #cell(show_details)="row">
          <b-button
            class="mr-2"
            size="sm"
            variant="outline-info"
            @click="row.toggleDetails"
          >
            {{ (row.item.IncidentOwnerPoint + row.item.IncidentByPoint).toFixed(2) }}
          </b-button>
        </template>
        <!-- 安全分详情 -->
        <template #row-details="row">
          <b-row align-h="end">
            <b-col
              cols="4"
              align-self="end"
            >
              <b-table-simple>
                <b-thead>
                  <b-tr>
                    <b-th>类型</b-th>
                    <b-th>次数</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>和别人碰撞</b-td>
                    <b-td>{{ row.item.IncidentOwnerCount }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>被别人碰撞</b-td>
                    <b-td>{{ row.item.IncidentByCount }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>单方碰撞</b-td>
                    <b-td>{{ row.item.IncidentSingleCount }}</b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr>
                    <b-td>总数</b-td>
                    <b-td>{{ row.item.IncidentOwnerCount + row.item.IncidentByCount + row.item.IncidentSingleCount }}</b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </b-col>
          </b-row>
        </template>
        <!-- 安全分结束 -->

        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>
      <span class="ml-2 mb-1 mt-2">
        第二轮
      </span>
      <b-table
        :fields="fields"
        :items="itemsR2"
        striped
        responsive
        class="mb-0 mt-1"
      >
        <template #cell(Driver)="data">
          <h-link
            v-if="data.item.Did > 0"
            :id="data.item.Did"
            :text="data.item.Driver"
            to="driver"
            type="driver"
          />
        </template>
        <!-- 安全分 -->
        <template #cell(show_details)="row">
          <b-button
            class="mr-2"
            size="sm"
            variant="outline-info"
            @click="row.toggleDetails"
          >
            {{ (row.item.IncidentOwnerPoint + row.item.IncidentByPoint).toFixed(2) }}
          </b-button>
        </template>
        <!-- 安全分详情 -->
        <template #row-details="row">
          <b-row align-h="end">
            <b-col
              cols="4"
              align-self="end"
            >
              <b-table-simple>
                <b-thead>
                  <b-tr>
                    <b-th>类型</b-th>
                    <b-th>次数</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>和别人碰撞</b-td>
                    <b-td>{{ row.item.IncidentOwnerCount }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>被别人碰撞</b-td>
                    <b-td>{{ row.item.IncidentByCount }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>单方碰撞</b-td>
                    <b-td>{{ row.item.IncidentSingleCount }}</b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr>
                    <b-td>总数</b-td>
                    <b-td>{{ row.item.IncidentOwnerCount + row.item.IncidentByCount + row.item.IncidentSingleCount }}</b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </b-col>
          </b-row>
        </template>
        <!-- 安全分结束 -->

        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>
    </div>

    <!-- 单轮的成绩显示 -->
    <div v-else>
      <b-table
        :fields="fields"
        :items="items"
        :tbody-tr-class="rowClass"
        :striped="striped"
        responsive
        class="mb-0"
      >
        <template #cell(Driver)="data">
          <h-link
            v-if="data.item.Did > 0"
            :id="data.item.Did"
            :text="data.item.Driver"
            to="driver"
            type="driver"
          />
        </template>
        <!-- 安全分 -->
        <template #cell(show_details)="row">
          <b-button
            class="mr-2"
            size="sm"
            variant="outline-info"
            @click="row.toggleDetails"
          >
            {{ (row.item.IncidentOwnerPoint + row.item.IncidentByPoint).toFixed(2) }}
          </b-button>
        </template>
        <!-- 安全分详情 -->
        <template #row-details="row">
          <b-row align-h="end">
            <b-col
              cols="4"
              align-self="end"
            >
              <b-table-simple>
                <b-thead>
                  <b-tr>
                    <b-th>类型</b-th>
                    <b-th>次数</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>和别人碰撞</b-td>
                    <b-td>{{ row.item.IncidentOwnerCount }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>被别人碰撞</b-td>
                    <b-td>{{ row.item.IncidentByCount }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>单方碰撞</b-td>
                    <b-td>{{ row.item.IncidentSingleCount }}</b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr>
                    <b-td>总数</b-td>
                    <b-td>{{ row.item.IncidentOwnerCount + row.item.IncidentByCount + row.item.IncidentSingleCount }}</b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </b-col>
          </b-row>
        </template>
        <!-- 安全分结束 -->

        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>
    </div>
  </b-card-code>
</template>

<script>
import Vue from 'vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BTableSimple, BThead, BTr, BTh, BTbody, BTd, BTfoot, BRow, BCol,
} from 'bootstrap-vue'
import axios from '@axios'
import HLink from '@/views/common/h-link.vue'

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BTfoot,
    BRow,
    BCol,
    HLink,
  },
  props: {
    rid: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      striped: true,
      roundFilter: '',
      fields: [
        { key: 'Position', label: '冲线' },
        { key: 'Driver', label: '车手' },
        { key: 'CarType', label: '车型' },
        { key: 'GridPosition', label: '发车位' },
        { key: 'FinishTime', label: '用时', formatter: this.toLapTime },
        { key: 'Laps', label: '圈数' },
        { key: 'BestLaptime', label: '最快圈', formatter: this.toLapTime },
        { key: 'PenaltyMass', label: '加重' },
        { key: 'PitStop', label: '进站' },
        { key: 'FinishStatus', label: '完赛', tdClass: this.getFinishiVariant },
        { key: 'Points', label: '积分' },
        { key: 'LadderDelta', label: 'Rating', formatter: this.getRating },
        { key: 'show_details', label: '碰撞总值' },
      ],
      items: [],
      IsDouble: false,
      RaceProperty: '',
      HasGroups: -1,
      Classes: [],
    }
  },
  computed: {
    itemsR1() {
      return this.items
        .filter(x => x.Round === 1)
    },
    itemsR2() {
      return this.items
        .filter(x => x.Round === 2)
    },
  },
  watch: {
    HasGroups: {
      deep: true,
      handler(newVal) {
        if (newVal === 1) {
          this.updateFinishNum()
        }
      },
    },
  },
  created() {
    axios.get(`${Vue.prototype.$Api_Race_Details}${this.rid}`)
      .then(response => {
        this.items = response.data
        this.Classes = [...new Set(this.items.map(x => x.Class))]
      })
    axios.get(`${Vue.prototype.$Api_Race_Property}${this.rid}`)
      .then(response => {
        if (response.data[0].IsDouble === 1) {
          this.IsDouble = true
        }
        this.RaceProperty = response.data[0].RaceProperty
        this.HasGroups = response.data[0].HasGroups
      })
  },
  methods: {
    toLapTime(value) {
      return this.$convertToLapTime(value)
    },
    getFinishiVariant(val) {
      let variant = ''
      if (val !== 'Finish') {
        variant = 'text-primary'
      }
      return variant
    },
    getRating(val) {
      let rating = val
      if (rating > 0) {
        rating = `+${val}`
      }
      return rating
    },
    updateFinishNum() {
      this.items.forEach(element => {
        const item = element
        item.Position = item.ClassPosition
      })
    },
    rowClass(item, type) {
      this.striped = false
      let content = ''
      if (type === 'row' && this.IsDouble === 1) {
        const i = this.Classes.indexOf(item.Class)
        content = `bg-stripe${i + 1}`
      }
      return content
    },
  },
}
</script>
