<template>
  <div>
    <!-- 第一行，赛事卡片 + 赛事属性 -->
    <div>
      <RaceDetailsInfo
        :rid="currentRaceId"
      />
    </div>

    <!-- 第二行，比赛结果和记录总结 -->
    <div>
      <RaceDetailsResult
        :rid="currentRaceId"
      />
    </div>
  </div>
</template>

<script>
import RaceDetailsInfo from './raceDetailsInfo.vue'
import RaceDetailsResult from './raceDetailsResult.vue'

export default {
  components: {
    RaceDetailsInfo,
    RaceDetailsResult,
  },
  data() {
    return {
      currentRaceId: 0,
    }
  },
  created() {
    this.currentRaceId = Number(this.$router.currentRoute.params.rid)
  },
}
</script>
